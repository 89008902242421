import "./src/styles/global.css";
import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import browserHydrateFunction from "./src/utilities/gatsby/browser-hydrate-function";

export const replaceHydrateFunction = browserHydrateFunction;

export const wrapPageElement = ({ element, props }) => {
  const isIndexPage = props.path === "/";
  return (
    <>
      {isIndexPage ? (
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.GATSBY_APP_RECAPTCHA_KEY}
        >
          {element}
        </GoogleReCaptchaProvider>
      ) : (
        <> {element}</>
      )}
    </>
  );
};
